import React from "react";

import "./web.css";
import Navbar from "../../Navbar";
import Footer from "../Footer/Footer";

function Webdev() {
  return (
    <>
      <Navbar />
      <div className="heading">
        <h4
          className="display-1 text-center mt-2"
          style={{ fontWeight: "800", color: "#8D1212" }}
        >
          Career Page
        </h4>
      </div>
      <div className="Webcontainer py-4">
        <h3 className="heading2">
          Apply as a Fulltime and a part-time training Instructor with us
        </h3>
      </div>

      <div className="col-sm-12 col-xs-12 col-lg-8 col-md-10 col-xl-8 mx-auto">
        <h1>Application is open</h1>
        <h1>Contact:08160197959</h1>
        <h2>Mail: contact@gmtsoftware.tech</h2>
      </div>
      <Footer />
    </>
  );
}

export default Webdev;
