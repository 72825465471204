import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Particle from "../component/Particles";
import "./Login.css";
// import axiosInstance from "../utils/axios";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/pages/Footer/Footer";
import { toast } from "react-toastify";
import { useStateValue } from "../stateProvider/stateProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "./LoadingSpinner";

const Login = () => {
  const [dispatch] = useStateValue();

  const [isLoading, setIsLoading] = useState(false);

  const initialFormData = Object.freeze({
    username: "",
    email: "",
    password: "",
  });
  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const form = useRef(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await axios.post(
        `https://studentportal.gmtsoftware.tech/api/user/login`,
        {
          username: formData.username,
          email: formData.email,
          password: formData.password,
        }
      );
      setIsLoading(true);
      if (res.data) {
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        axios.defaults.headers["Authorization"] =
          "JWT " + localStorage.getItem("refresh_token");
        //set both tokens and user in local storage
        const message = {
          User: formData.email,
          token: res.data,
        };
        //save data and token to local storage
        localStorage.setItem("user", JSON.stringify(message));
        // save user and token to reducer
        dispatch({
          type: "LOGGED_IN_USER",
          payload: message,
        });
        setIsLoading(true);
        setTimeout(() => (window.location.href = "/Dashboard"), 500);
      }
      toast.success("login successfully");
    } catch (err) {
      if (err)
        toast.error("Invalid Email/Password try again..", {
          closeOnClick: true,
        });
    }
    updateFormData(initialFormData);
    form.current.reset();
  };

  return (
    <>
      <Navbar />
      <Particle />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="card mx-auto mb-5 d-flex justify-content-center py-auto align-items-center Signin">
          <div className="card-body">
            <ToastContainer position="top-center" />
            <div className="card-title">Login</div>
            <div className="login-container">
              <form ref={form} className="login-form" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label for="user-username" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="userName"
                    name="username"
                    placeholder="Enter your username"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label for="user-email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="userEmail"
                    name="email"
                    placeholder="Enter email"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label for="user-password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="userPassword"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                  />
                </div>
                <button type="submit" className="btn btn-primary form-control">
                  LOGIN
                </button>

                <p className="create-account">
                  Don't have an account?
                  <span>
                    {" "}
                    <Link to="/Signup" className="sign-up-link">
                      SIGN UP
                    </Link>
                  </span>
                </p>
              </form>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Login;
