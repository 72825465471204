import React, { useEffect } from "react";
import { FiWifiOff } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Failure() {
  useEffect(() => {
    document.title =
      "Artificial intelligience | data science training in Abuja";
  }, []);
  return (
    <>
      <Helmet>
        <title>Artificial intelligience | data science training in Abuja</title>
        <link
          rel="canonical"
          href="https://www.gmtsoftware.tech/data-science-training-course-in-abuja-nigeria/"
        ></link>

        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_us" />
        <meta
          property="og:url"
          content="https://www.gmtsoftware.tech/data-science-training-course-in-abuja-nigeria/"
        />
        <meta
          property="og:title"
          content="Artificial intelligience | data science training in Abuja"
        />
        <meta
          property="og:description"
          content="Artificial intelligience/Machine Learning Training in Abuja, Nigeria
      Unlock the Power of AI
      
      Our machine learning training program in Abuja equips you with the skills to develop intelligent systems that can learn from data and make predictions. Learn from experienced data scientists and master the latest techniques and tools."
        />
        <meta property="og:image" content="./images/logo.png" />
        <meta property="og:image:type" content="./images/logo.png" />
        <meta property="og:image:width" content="359" />
        <meta property="og:image:height" content="359" />
        <meta property="og:site_name" content="GMTsoftware" />
        <meta
          property="og:see_also"
          content="https://www.twitter.com/GMTsoftware"
        />
        <meta
          property="og:see_also"
          content="https://www.facebook.com/GmtsoftwareNG"
        />
        <meta
          name="keywords"
          content="Fullstack Software Development Training: Full-stack development, front-end development, back-end development, and more.
      Data Analytics: Data analysis, business intelligence, and data science.
      Cybersecurity: Cybersecurity fundamentals, ethical hacking, and digital forensics.
      Mobile App Development training in Abuja: Mobile app development using frameworks like React Native.
      Design: UI/UX design and graphic design.
      Programming Languages: Python, Java, C++, and more.Digital Marketing training in Abuja: SEO, content, social media, Web Development: HTML, CSS, JavaScript, frameworks, Graphic Design: Photoshop, Illustrator, design principles,Content Creation: Writing, video, audio,E-commerce: Online store setup, marketing,Data Analytics: Data analysis tools, statistics, machine learning,Cyber Security: Ethical hacking, network security,UI/UX Design: User experience, design software,App Development: Swift, Kotlin, React Native,Virtual Assistance: Administrative tasks, customer service, Fullstack Web Development, Frontend Web development,.NET C# Programming, HTML5, Web API & .NET Core training in Abuja,Microsoft-Excel-Powerpoint-training-in-Abuja,Artificial intelligience/Machine Learning Training in Abuja "
        />
        <meta
          name="description"
          content="Artificial intelligience/Machine Learning Training in Abuja, Nigeria
      Unlock the Power of AI
      
      Our machine learning training program in Abuja equips you with the skills to develop intelligent systems that can learn from data and make predictions. Learn from experienced data scientists and master the latest techniques and tools."
        />
      </Helmet>
      <div className="container-fluid">
        <div className="container">
          <h1 className="text-center display-3 py-5">
            Oops Application Not Successful!
          </h1>
          <p className="py-3 text-center">
            Please try again, if it's persist check your internet connection
            <FiWifiOff size={35} />
          </p>
          <div className="text-center">
            <Link to="/sign-up" className="btn btn-normal btn-dark m-5">
              Try again
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Failure;
