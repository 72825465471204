import React from "react";
import "./Timetable.css"; // Import CSS file

const Timetable = () => {
  const courses = [
    {
      name: "Fundamental Computer Literacy Program",
      timeline: "1 month",
      details:
        "Computer Fundamentals, Microsoft Office - Word, Excel, Powerpoint, Outlook, Paint",
      registrationStatus: "ongoing",
    },
    {
      name: "Software Development",
      timeline: "4 month",
      details:
        "HTMLS,CSS3,CORE JAVASCRIPT PROGRAMMING,ANGULAR,REACTJS,NODEJS,DATABASE TRACKS,Full development lifecycle, Agile methodologies, Version Control (Git)",
      registrationStatus: "ongoing",
    },
    {
      name: "DATABASE TRACK",
      timeline: "2 month",
      details:
        "Database Design & Implementation, Ms SQL Server, MariaDB, MongoDB",
      registrationStatus: "ongoing",
    },
    {
      name: "Data Analytic",
      timeline: "2 month",
      details: "Power BI, Microsoft Excel, R, Python,sql",
      registrationStatus: "Ongoing",
    },
    {
      name: "Product Design & Graphic designers",
      timeline: "2 month & 2 weeks",
      details:
        "approach UX and usability,Understanding basic functions,Color balancing and mixing ,How to design an App for android/Iphone/web, Wireframing,Prototyping",
      registrationStatus: "Ongoing",
    },
    {
      name: "Python(Backend Development)",
      timeline: "2 month",
      details: "Core python programming, Web App Development using Django",
      registrationStatus: "Ongoing",
    },
    {
      name: "Cybersecurity",
      timeline: "3 month",
      details:
        "Networking,Understanding Malware and phishing,network Security,Web Security, Ethical Hacking,Cloud Security",
      registrationStatus: "Ongoing",
    },
    {
      name: "Mobile Development",
      timeline: "3 month",
      details:
        "pre-requisite(web knowledge), React-Native/Flutter, Java(Kotlin) ",
      registrationStatus: "Ongoing",
    },
    {
      name: ".Net technologies",
      timeline: "2 month",
      details: "c# language programming, HTML5, Web Api, .Net Core",
      registrationStatus: "Ongoing",
    },
    {
      name: "Dev-ops",
      timeline: "2 month",
      details: "CI/CD ..etc",
      registrationStatus: "Ongoing",
    },
    {
      name: "Java technologies",
      timeline: "2 month",
      details:
        "core java programming, Enterprise/web/Cloud development,Workflow Automation",
      registrationStatus: "Ongoing",
    },
    {
      name: "Accounting Software",
      timeline: "2 month",
      details: "Intuit Quickbook, Xero, Sage 50 accounting",
      registrationStatus: "Ongoing",
    },
    {
      name: "Basic Robotic",
      timeline: "2 month",
      registrationStatus: "Ongoing",
    },
    {
      name: "Digital Marketing",
      timeline: "2 month",
      registrationStatus: "Ongoing",
    },
    {
      name: "3d and 2d animation",
      timeline: "2 month",
      details: "3d modelling and 2d modelling",
      registrationStatus: "Ongoing",
    },
    {
      name: "Virtual Assistance Training",
      timeline: "2 month",
      details: "Customer Support, Social Media, Content Creation",
      registrationStatus: "Ongoing",
    },
    {
      name: "Blockchain and cryptocurrency training",
      timeline: "2 month",
      details: "Web3, Solidity, ether",
      registrationStatus: "Ongoing",
    },
    {
      name: "ecommerce-website-design-training",
      timeline: "1 month",
      details: "Wordpress",
      registrationStatus: "Ongoing",
    },
    {
      name: "Adobe Illustrator and indesigns,premier pro",
      timeline: "1 month",
      details: "Adobe suite",
      registrationStatus: "Ongoing",
    },
  ];

  return (
    <div>
      <h2>Our Courses</h2>
      <table className="timetable">
        <thead>
          <tr>
            <th>Course</th>
            <th>Timeline</th>
            <th>details</th>
            <th>Registration</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course, index) => (
            <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
              <td data-label="Course">{course.name}</td>
              <td data-label="Timeline">{course.timeline}</td>
              <td data-label="Details">{course.details}</td>
              <td data-label="Registration">{course.registrationStatus}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Timetable;
