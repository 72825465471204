import React from 'react'
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

function Points() {
  return (
    <div><LeaderboardIcon style={{fontSize: "80px"}}/>
     <h4 className='pt-2 text-align-center'>Total Point Score/100</h4>
      
    </div>
  )
}

export default Points