import React from 'react'
import './Home3.css'

function Home7() {
  return (
    <>
    <div className="container mt-5">
    <h2 className='text-center' style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"33px", fontWeight:"500"}}>Gallery</h2>
    <hr style={{fontWeight:'10px' ,color:"red"}}/>
    <p className='text-center' style={{fontSize:"16px",}}><i>
    </i></p>
    <div class="row row-cols-1 row-cols-md-2 g-4">
    <div class="col">
    <div class="card">
    <img
                  src="./img/18.jpg"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Coding Section</h5>
        <p class="card-text"></p>
            </div>
  </div>
    </div>
    <div class="col">
    <div class="card">
    <img
                  src="./img/12.jpg"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Coding Section</h5>
       
      </div>
      <div class="card-body">
   
  </div>
    </div>
  </div>
  </div>

<a href="/gallery" className="btn text-align-center btn-dark btn-md-center">                 
                  <span>
                  See More Pictures 👉
                  </span>
                </a>
</div>
    </>
  )
}

export default Home7