import React, { useEffect } from "react";
import { FaLaptopCode } from "react-icons/fa";
import { FaServer } from "react-icons/fa";
import { GiCrystalize } from "react-icons/gi";
import Navbar from "../../Navbar";
import ControlledCarousel from "./Carousel";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function About() {
  useEffect(() => {
    document.title =
      "Business Analytics | 3d-modelling-animation-in-abuja training in Abuja";
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Business Analytics | 3d-modelling-animation-in-abuja training in Abuja
        </title>
        <link
          rel="canonical"
          href="https://www.gmtsoftware.tech/3d-modelling-animation-in-abuja/"
        ></link>

        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_us" />
        <meta
          property="og:url"
          content="https://www.gmtsoftware.tech/3d-modelling-animation-in-abuja/"
        />
        <meta
          property="og:title"
          content=" Business Analytics | 3d-modelling-animation-in-abuja training in Abuja"
        />
        <meta
          property="og:description"
          content="How to Become a 3d-modelling-animation Designer in Nigeria
Discover Your Path to Creative Success

Motion graphics designers create visually stunning animations and effects for various media, including videos, advertisements, and presentations. If you have a passion for creativity and technology, a career in motion graphics design in Nigeria could be the perfect fit for you"
        />
        <meta property="og:image" content="./images/logo.png" />
        <meta property="og:image:type" content="./images/logo.png" />
        <meta property="og:image:width" content="359" />
        <meta property="og:image:height" content="359" />
        <meta property="og:site_name" content="GMTsoftware" />
        <meta
          property="og:see_also"
          content="https://www.twitter.com/GMTsoftware"
        />
        <meta
          property="og:see_also"
          content="https://www.facebook.com/GmtsoftwareNG"
        />
        <meta
          name="keywords"
          content="Fullstack Software Development Training: Full-stack development, front-end development, back-end development, and more.
      Data Analytics: Data analysis, business intelligence, and data science.
      Cybersecurity: Cybersecurity fundamentals, ethical hacking, and digital forensics.
      Mobile App Development training in Abuja: Mobile app development using frameworks like React Native.
      Design: UI/UX design and graphic design.
      Programming Languages: Python, Java, C++, and more.Digital Marketing training in Abuja: SEO, content, social media, Web Development: HTML, CSS, JavaScript, frameworks, Graphic Design: Photoshop, Illustrator, design principles,Content Creation: Writing, video, audio,E-commerce: Online store setup, marketing,Data Analytics: Data analysis tools, statistics, machine learning,Cyber Security: Ethical hacking, network security,UI/UX Design: User experience, design software,App Development: Swift, Kotlin, React Native,Virtual Assistance: Administrative tasks, customer service, Fullstack Web Development, Frontend Web development,.NET C# Programming, HTML5, Web API & .NET Core training in Abuja,Microsoft-Excel-Powerpoint-training-in-Abuja,Artificial intelligience/Machine Learning Training in Abuja, Kids Coding training in Abuja ,Blockchain and Cryptocurrency Training in Abuja, Nigeria
      "
        />
        <meta
          name="description"
          content="How to Become a 3d-modelling-animation Designer in Nigeria
Discover Your Path to Creative Success

Motion graphics designers create visually stunning animations and effects for various media, including videos, advertisements, and presentations. If you have a passion for creativity and technology, a career in motion graphics design in Nigeria could be the perfect fit for you"
        />
      </Helmet>
      <Navbar />
      <div className="jumbotro">
        <div className="container py-auto">
          <h2
            className=" pt-5 text-center text-white"
            style={{ fontSize: "52px" }}
          >
            Immersive Experiences that changes Lives
          </h2>
          <mark
            className=" py-1 text-center text-white bg-dark"
            style={{ fontSize: "1.5rem" }}
          >
            About Gmtsoftware bootcamp Experience
          </mark>
        </div>
      </div>

      {/* <div className="row">

          <button className="btn m-2 col-md-5 btn-block" style={{ background:"#8D1212", color:"white"}}>Apply Now</button>
            <button className="btn m-2 col-md-5 btn-block bg-dark" style={{ color: "white" }}>Download Curriculum</button>
            </div> 

      {/* 
        <div className="container py-5">
          <div className="row">
            <div className="card  mb-3 mr-2 col-lg-6 col-md-6 col-sm-12 ">
              <div className="card-header text-center">
                <h5 className="card-title">MISSION</h5>
              </div>
              <div className="card-body">
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
            <div className="card text-white bg-secondary mb-3 col-lg-6 col-md-6 col-sm-12">
              <div className=" card-title text-center">
                <h5 className="card-title">VISION</h5>
              </div>
              <div className="card-body">
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 col-xs-12 mt-4">
            <h1
              style={{
                fontWeight: "500",
                fontSize: "44px",
                lineHeight: "48px",
                letterSpacing: "-.6px",
              }}
            >
              About GMTsoftware
            </h1>
            <h4
              style={{
                fontWeight: "500",
                lineHeight: "1.6rem",
                fontSize: "1rem",
                letterSpacing: "-.6px",
                textJustify: "center",
              }}
            >
              {" "}
              GMTsoftware is a design and coding bootcamp founded in 2020 by
              professionals who had their lives dramatically changed by learning
              tech skills. GMTsoftware specializes in immersive, in-person, and
              online training bootcamps. The company is primarily focused on
              coding and softwares which are of relevant use to individuals and
              businesses.
            </h4>
          </div>
          <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 col-xs-12 mt-4">
            <img
              src="./images/ceogmt.jpg"
              alt="img"
              style={{ borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
      <div className="container py-5 text-center">
        <h1
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "48px",
            letterSpacing: "-.6px",
          }}
        >
          In-Person Courses and Community
        </h1>
        <p
          className="text-center"
          style={{
            fontWeight: "300",
            lineHeight: "1.6rem",
            fontSize: "1rem",
            letterSpacing: "-.6px",
          }}
        >
          Our intense courses in Web Development, Python are hands-on,
          project-based, and are designed by educators with real-world industry
          knowledge. We offer challenging, focused training designed to equip
          students with the skills to start their journey toward a career in
          tech.
        </p>
      </div>
      <div className="container ">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="text-center"
        >
          <div className="text-center">
            <FaLaptopCode size={50} color="#b82f24" />
            <p
              style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              className="text-center"
            >
              FRONT-END DEVELOPMENT
            </p>
          </div>
          <div className="text-center">
            <FaServer size={50} color="darkGreen" />
            <p
              style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              className="text-center"
            >
              CYBERSECURITY
            </p>
          </div>
          <div className="text-center">
            <GiCrystalize size={50} color="darkBlue" />
            <p
              style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              className="text-center"
            >
              FULL-STACK DEVELOPMENT
            </p>
          </div>
        </div>
      </div>
      <div className="jumbotron text-center">
        <div className="container py-3 ">
          <div className="row py-2">
            <div className="col-lg-6 col-sm-12 col-xl-6 col-xs-12 py-5">
              <span>
                <h1
                  style={{
                    fontWeight: "800",
                    fontSize: "48px",
                    lineHeight: "32px",
                    letterSpacing: "-.6px",
                  }}
                >
                  50+
                </h1>
                Students Graduated
              </span>
            </div>

            <div className="col-lg-6 col-sm-12 col-xl-6 col-xs-12 py-5">
              <span>
                <h1
                  style={{
                    fontWeight: "800",
                    fontSize: "48px",
                    lineHeight: "32px",
                    letterSpacing: "-.6px",
                  }}
                >
                  5.8k+
                </h1>
                Average Course Report Rating
              </span>
            </div>
          </div>
        </div>
      </div>
      <h1
        className="text-center"
        style={{
          fontWeight: "500",
          fontSize: "44px",
          lineHeight: "48px",
          letterSpacing: "-.6px",
        }}
      >
        About Our Team
      </h1>

      <ControlledCarousel />
      {/* <div className="container py-5">
         <div className="row">
         <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 col-xs-12 text-center pb-4 my-auto">
            <img src="./images/bayo.jpg" width="191" height="191" alt="img" style={{borderRadius:"75%"}}/>
        </div>
        <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 col-xs-12 text-center">
            <h1 style={{fontWeight:"700", fontSize:"28px", lineHeight:"24px", letterSpacing:"-.6px"}}>Owolabi Adebayo Stephen</h1>
        

        </div>
        
                </div>
                </div>   
                <div className="container py-5">
         <div className="row">
         <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 col-xs-12 my-auto text-center ">
            <img src="./images/frz.png" alt="img" />
        </div>
        <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 col-xs-12 mt-4 text-center">
            <h1 style={{fontWeight:"700", fontSize:"28px", lineHeight:"24px", letterSpacing:"-.6px"}}>Owolabi Adebayo Stephen</h1>
           <h4 style={{fontWeight:"500", fontSize:"22px", lineHeight:"32px", letterSpacing:"-.6px"}}> Adebayo is a Software Developer in Maitama Abuja. Adebayo has a Bachelor'Degre in Electrical/Electronic. He spends most of his time working with Javascript, CSS3, and HTML5,React and he is passionate about everything related to software developemnt and a great teacher
           He will be introducing the student to computer science and further-more He will be taken teh front-end development courses </h4>
           

        </div>
        
                </div>
                </div>    */}
      <Footer />
    </>
  );
}

export default About;
