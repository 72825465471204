import React from 'react'
import Navbar from '../../Navbar'
import "./Home3.css"

function Project() {
  return (
    <>
    <Navbar/>
    <div className="container mt-5">
    <h2 className='text-center' style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"33px", fontWeight:"500"}}>Featured Participants' Bootcamp Projects</h2>
    <hr style={{fontWeight:'10px' ,color:"red"}}/>
    <p className='text-center' style={{fontSize:"16px",}}><i>
    We adopted project-based learning. Here are some applications developed by our participants during the programme:</i></p>
    <div class="row row-cols-1 row-cols-md-2 g-4">
  <div class="col">
    <div class="card text-center">
    <img src="./images/samantha.gif"
                  alt=""
                  width="100"
                  className='img-fluid card-img-top'
                />
      <div class="card-body">
        <h6 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Product Design For a Soul Store with wallet by Samantha</h6>
        <p className='text-center' style={{fontSize:"12px",}}><i>(August 2021 set)</i></p>
        <p class="card-text">
        <p>User-Friendly Design. Perhaps the most important feature customers want in an ecommerce site is a good user experience.</p>
        <p>Mobile-Friendly Features. ...</p>
        <p>Multiple Payment Options. ...</p>
        <p>24/7 Customer Service. ...</p>
        <p>User Reviews. ...</p>
        <p>User Features and Discounts. ...</p>
        Extensive Product Information.
        </p>
      </div>
      <div class="card-body">
        <label>Click Here to view 👉</label>
    <a href="https://xd.adobe.com/view/b873eb23-f1e1-4dd0-9f09-48010b387732-ed20/" class="card-link">Project Link</a>
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/mariam.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
   <div class="card-body">
        <h6 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Product Design/ Ui/Ux  For a Sweet and Sexy Lingeries Store by Mariam</h6>
        <p className='text-center' style={{fontSize:"12px",}}><i>(April 2022 set)</i></p>
        <p class="card-text">
        <p>User-Friendly Design. Perhaps the most important feature customers want in an ecommerce site is a good user experience.</p>
        <p>Mobile-Friendly Features. ...</p>
        <p>Multiple Payment Options. ...</p>
        <p>24/7 Customer Service. ...</p>
        <p>User Reviews. ...</p>
        <p>User Features and Discounts. ...</p>
        Extensive Product Information.
        </p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉</label>
    <a href="https://1drv.ms/b/s!AkDncPPZYgzrbOYKJNsQ-ZxA8_E" class="card-link">Project Link</a>
  </div>
      
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/brightbooks.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}} >Brightbooks Online Book store by bukunmi</h5>
        <p class="card-text">This store was develop by Adeyemo bukunmi, it is a mern stack project(Mongodb, React, Node, Express) its give access to both customers to choose book of there choice and get there books and also the admin to upload.</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href="https://github.com/BukunmiPeter/Brightbooks-Ecommerce-frontend" class="card-link">Github Link</a>
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/backend.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>GMTsoftware Blog Backend by Usman</h5>
        <p class="card-text">This was developed by Usman and Adebayo, it make use of django restfull framework to develop a backend for gmtsoftware blog Projects</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href="https://gmtblog-api.herokuapp.com/" class="card-link">Project Link</a>
    <a href="https://github.com/owolabiadebayo/gmtblog-api" class="card-link">Github Link</a>
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/portal.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Student Portal system</h5>
        <p class="card-text">This project was execute by dada wole,adeyemo bukunmi, and Owolabi Adebayo. The technology used is react-js with material-UI and python (Django) for backend its used to manage student task and monitor there progress.</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href="https://gmtsoftware.tech/Dashboard" class="card-link">Project Link</a>
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/patientappointment.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Hospital Management System</h5>
        <p class="card-text">Patient appointment system helps you manage appointments and seamlessly check-in patients to see consultants on arrival. Therefore, staff can focus on meeting and supporting patients to deliver a better all-round experience.</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href=" https://github.com/owolabiadebayo/Online-Hospital-Management" class="card-link">Github Link</a>
   
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/client-bank.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Client-Support Landing Page by olaitan</h5>
        <p class="card-text">A client support system using tailwindcss developed by olaitan</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href=" https://client-support.netlify.app/" class="card-link">Project Link</a>
    <a href=" https://github.com/Laitandada/Customer-Support-Landing-Page" class="card-link">Github Link</a>
   
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/discord.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Discord Replica Using HTML and CSS by Martins</h5>
        <p class="card-text">A discord replica task was given to martins to train him on how to replicate a figma wireframe</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href=" https://github.com/martinsgit1211/CSS-Project-On-Discord-Replica" class="card-link">Github Link</a>
    <a href=" https://discord-replica.netlify.app/" class="card-link">Github Link</a>
   
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/house.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Real Estate Landing by Amos</h5>
        <p class="card-text">A discord replica task was given to Amos to train him on how to replicate a figma wireframe</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href="https://github.com/Amosjaf/HouseZillar-mockup" class="card-link">Github Link</a>
    <a href="https://housezillar-mockup.pages.dev/" class="card-link">Project Link</a>
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/fintech.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>UI/UX design Design of Sunrise Banking App by James</h5>
        <p class="card-text">  <p class="card-text">
        <p>User-Friendly Design. Perhaps the most important feature customers want in an fintech site is a good user experience.</p>
        <p>Mobile-Friendly Features. ...</p>
        <p>Security. ...</p>
        <p>Payment System</p>
        <p>Streamlined Onboarding ...</p>
        <p>Simplify complex data into action</p>
        <p>User Features and Friction-Free Forms ...</p>
        Personalized UI and Settings. ...
        </p></p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href=" https://drive.google.com/file/d/1nVFDRIibEPkm2NXE9khU1UweRvTn3C5z/view?usp=sharing" class="card-link">project Link</a>
   
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/flight.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>UI/UX Design Airline Reservation and Flight Booking System by Arinze</h5>
        <p class="card-text">
        <p class="card-text">User-Friendly Design. Perhaps the most important feature customers want in an flight Booking App site is a good user experience.</p>
        <p class="card-text">Mobile-Friendly Features. ...</p>
        <p class="card-text">Flight booking. ...</p>
        <p class="card-text">Streamlined Onboarding ...</p>
        <p class="card-text">Simplify complex data into action</p>
        <p class="card-text">User Features and Friction-Free Forms ...</p>
        Personalized UI and Settings. ...
        </p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href="https://drive.google.com/file/d/1c2PPFrOJlObIBagbRYziC43bekVa-AMM/view?usp=sharing" class="card-link">Project Link</a>
   
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/foodapp.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Food Ordering App</h5>
        <p class="card-text">An online ordering application where a food vendor/ restaurant can display their menu; allowing their prospective customers to place order at their convenience.</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href=" https://drive.google.com/file/d/1osQSfNj_fiag4DtgGHztKw9xZ3j93OnE/view?usp=sharing" class="card-link">Project Link</a>
   
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/wireframe.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Wireframe For Car Ordering App</h5>
        <p class="card-text">WireFrame is part of UI/UX where a designer plans his design process</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href=" https://drive.google.com/file/d/1LSzD9spOnTSuIelVH5y5TfYNtOJw4A6v/view?usp=sharing" class="card-link">Project Link</a>
   
  </div>
    </div>
  </div>
</div>
</div>
    </>
  )
}

export default Project