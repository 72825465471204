import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import { styled } from "@mui/material/styles";
import Navbar from "../../Navbar";
import Footer from "../Footer/Footer";

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

export default function Gallery() {
  return (
    <>
      <Navbar />
      <Box sx={{ minwidth: 800, minHeight: 800 }}>
        <Masonry columns={3} spacing={2}>
          {itemData.map((item, index) => (
            <div key={index}>
              <Label>{item.title}</Label>
              <img
                src={`${item.img}?w=162&auto=format`}
                srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
                style={{
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  display: "block",
                  width: "100%",
                }}
              />
            </div>
          ))}
        </Masonry>
      </Box>
      <Footer />
    </>
  );
}
//
const itemData = [
  {
    img: "/img/1.jpg",
    title: "Dev",
  },
  {
    img: "/img/2.jpg",
    title: "Coding Section",
  },
  {
    img: "/img/3.jpg",
    title: "collaboration",
  },
  {
    img: "/img/4.jpg",
    title: "Starting Point",
  },
  {
    img: "/img/5.jpg",
    title: "Team member",
  },
  {
    img: "/img/6.jpg",
    title: "Team work",
  },
  {
    img: "/img/chukwudi2.jpg",
    title: "Cert presentation",
  },
  {
    img: "/img/8.jpg",
    title: "Team ",
  },
  {
    img: "/img/9.jpeg",
    title: "Certificate Presentation",
  },
  {
    img: "/img/10.jpg",
    title: "Product Designer",
  },
  {
    img: "/img/11.jpg",
    title: "Coding Bootcamp",
  },
  {
    img: "/img/12.jpg",
    title: "lecture",
  },
  {
    img: "/img/13.jpg",
    title: "GMTsoftware",
  },
  {
    img: "/img/14.jpg",
    title: "Coding section",
  },
  {
    img: "/img/15.jpg",
    title: "Coding Section",
  },
  {
    img: "/img/16.jpg",
    title: "Dev",
  },
  {
    img: "/img/17.jpg",
    title: "Coding Section",
  },
  {
    img: "/img/18.jpg",
    title: "Coding Section",
  },
  {
    img: "/img/more.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more2.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more3.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more4.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more5.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more6.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more10.jpg",
    title: "Group work",
  },
  {
    img: "/img/more8.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more11.jpg",
    title: "Group Work",
  },
  {
    img: "/img/more12.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more9.jpg",
    title: "Learning section",
  },
  {
    img: "/img/more14.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more17.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more19.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more18.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more21.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more20.jpg",
    title: "Certificate presentation",
  },
  {
    img: "/img/more22.jpg",
    title: "Certificate presentation",
  },

  { img: "/img/ab7_0015.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0017.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0018.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0019.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0020.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0051.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0052.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0070.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0024.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0066.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0026.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0027.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0031.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0043.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0048.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0049.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0035.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0037.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0038.jpg", title: "GMTsoftware Anniversary" },
  { img: "/img/ab7_0036.jpeg", title: "GMTsoftware graduation" },
  { img: "/img/rofy.jpeg", title: "GMTsoftware graduant" },
  { img: "/img/rofiyatset.jpeg", title: "GMTsoftware" },
];
