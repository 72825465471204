import React from 'react';
import { Navigate, Outlet} from 'react-router-dom';
// import { useSelector } from "react-redux";


// const PrivateRoute = ({ children }: { children: JSX.Element }) => {
//     console.log({...rest});
//     const Navigate= useNavigate();
   
//     const { auth } = useSelector((state) => ({ ...state }));

//     // If authorized, return an outlet that will render child elements
//     // If not, return element that will navigate to login page
//     return auth && auth.token && <Navigate to="/login" state={{ from: location }} replace />
// }

const PrivateRoute = () => {
    // const { auth } = useSelector((state) => ({ ...state }));
    // console.log(auth);
    const loginauth = window.localStorage.getItem("user");
    const auths = JSON.parse(loginauth);
    console.log(auths);
    
  
    
    return auths && auths.User ? <Outlet /> : <Navigate to="/login" />;
  }

  export default PrivateRoute;