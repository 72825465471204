import React, { useEffect } from "react";
import "./Pricing.css";
import Navbar from "../../Navbar";
import Footer from "../Footer/Footer";
import { FaDownload } from "react-icons/fa";
import Pdf from "../document/dataanalysis.pdf";
import { Helmet } from "react-helmet";

function Datascience({ courseData }) {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Course",
    name: "Data Analysis Training in Abuja, Nigeria", // Replace with actual name
    description: courseData.description, // Replace with actual description of the course
    provider: {
      "@type": "Organization",
      name: "GMTsoftware",
    },
    url: courseData.url, // Replace with actual URL of the course page
    sameAs: courseData.sameAs, // Replace with actual social media links
    image: courseData.image,
    offers: {
      "@type": "Offer",
      priceCurrency: "NGN", // Nigerian Naira
      price: 200000, // Adjust the price as needed
      availability: "https://schema.org/InStock",
      category: "Course",
    }, // Replace with actual image URL representing the course
    educationalUse: "FormalEducation", // Specify formal education use
    hasPart: [
      // Add course modules here
      {
        "@type": "CourseModule",
        name: "Data Cleaning and Preparation",
        description:
          "Learn techniques for cleaning, organizing, and preparing data for analysis.",
      },
      {
        "@type": "CourseModule",
        name: "Data Visualization",
        description:
          "Create effective visualizations to communicate data insights.",
      },
      {
        "@type": "CourseModule",
        name: "Statistical Analysis",
        description: "Apply statistical methods to analyze and interpret data.",
      },

      // Add more course modules as needed, following the same format
    ],
    hasCourseInstance: [
      {
        "@type": "CourseInstance",
        name: "Data Analysis Training in Abuja, Nigeria", // Specific instance name
        startDate: "2024-01-01", // Start date of the instance
        endDate: "2024-12-01", // End date of the instance
        location: {
          "@type": "Place",
          name: "GMTsoftware Computer Training School Abuja Office", // Location name (optional)
        },
      },
      {
        // Online self-paced course that takes 2 days to complete.
        "@type": "CourseInstance",
        courseMode: "Online",
        courseWorkload: "P2D",
      },
      // Add more objects for additional instances with different details
    ],
  };
  useEffect(() => {
    document.title =
      "Python for Data Science | Data Analytics training in Abuja";
  }, []);
  return (
    <>
      <Helmet>
        <title>Python for Data Science | Data Analysis training in Abuja</title>
        <link
          rel="canonical"
          href="https://www.gmtsoftware.tech/data-analytics-training-in-abuja/"
        ></link>

        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_us" />
        <meta property="og:url" content="https://www.gmtsoftware.tech/data-analytics-training-in-abuja/" />
        <meta
          property="og:title"
          content="Python for Data Science | Data Analytic training in Abuja"
        />
        <meta
          property="og:description"
          content="Learn Data analytics in gmtsoftware computer training center in Abuja.

      Are you ready to unlock the power of data? Our data analytics training program equips you with the essential skills and knowledge to become a proficient data analyst. Whether you're a seasoned professional looking to upskill or a recent graduate starting your career, our program offers a comprehensive curriculum tailored to your needs."
        />
        <meta property="og:image" content="./images/logo.png" />
        <meta property="og:image:type" content="./images/logo.png" />
        <meta property="og:image:width" content="359" />
        <meta property="og:image:height" content="359" />
        <meta property="og:site_name" content="GMTsoftware" />
        <meta
          property="og:see_also"
          content="https://www.twitter.com/GMTsoftware"
        />
        <meta
          property="og:see_also"
          content="https://www.facebook.com/GmtsoftwareNG"
        />
          <meta
          name="keywords"
          content="Fullstack Software Development Training: Full-stack development, front-end development, back-end development, and more.
      Data Analytics: Data analysis, business intelligence, and data science.
      Cybersecurity: Cybersecurity fundamentals, ethical hacking, and digital forensics.
      Mobile App Development training in Abuja: Mobile app development using frameworks like React Native.
      Design: UI/UX design and graphic design.
      Programming Languages: Python, Java, C++, and more.Digital Marketing training in Abuja: SEO, content, social media, Web Development: HTML, CSS, JavaScript, frameworks, Graphic Design: Photoshop, Illustrator, design principles,Content Creation: Writing, video, audio,E-commerce: Online store setup, marketing,Data Analytics: Data analysis tools, statistics, machine learning,Cyber Security: Ethical hacking, network security,UI/UX Design: User experience, design software,App Development: Swift, Kotlin, React Native,Virtual Assistance: Administrative tasks, customer service, Fullstack Web Development, Frontend Web development,.NET C# Programming, HTML5, Web API & .NET Core training in Abuja,Microsoft-Excel-Powerpoint-training-in-Abuja,Artificial intelligience/Machine Learning Training in Abuja "
        />
        <meta
          name="description"
          content="Learn Data analytics in gmtsoftware computer training center in Abuja.

      Are you ready to unlock the power of data? Our data analytics training program equips you with the essential skills and knowledge to become a proficient data analyst. Whether you're a seasoned professional looking to upskill or a recent graduate starting your career, our program offers a comprehensive curriculum tailored to your needs."
        />
      </Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      <Navbar />
      <div className="container-fluid bg-dark text-white">
        <div className="container py-5 header_one">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <p
                style={{
                  background: "rgb(149, 29, 29)",
                  color: "#fff",
                  display: "inline",
                }}
              >
                LEARN FROM THE INDUSTRY'S TOP TALENT
              </p>
              <h1 className="text-white">
                Data Analysis Accelerator Course: Abuja
              </h1>
              <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
                Data analysis is the process of inspecting, cleaning,
                transforming, and modeling data in order to derive useful
                information that can be used for decision-making purposes.
              </p>
              <div className="container my-4">
                <a
                  href="https://gmtsoftware.tech/Apply-for-GMTsoftware-Training"
                  className="ml-3 btn btn-danger btn-md"
                  style={{
                    marginRight: "10px",
                    background: "rgb(149, 29, 29)",
                  }}
                >
                  Apply Now
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img
                src="https://railsware.com/blog/wp-content/uploads/2018/10/Python-for-Data-Science-facebook.png"
                alt="img"
                className="img-fluid shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="py-4 w-75" style={{ fontWeight: "1000" }}>
          Demand for data analyst has increased 663% in five years.
        </h2>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4>Boost Your Professional Value With Versatile Skills</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              {" "}
              Thousands of companies need team members who can transform data
              sets into strategic forecasts. Meet that need.The goal of data
              analyst is to make sense of complex data sets by identifying
              patterns, correlations, and other relationships that can help
              inform decisions or drive action.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <h4> Tap Into a Valuable Professional Network</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              If you’re someone who is obsessed with using technology to make
              the world a better or more interesting place, yet you never lose
              track of design, you’ll be right at home with the unorthodox
              individualists in our Data analysis program.{" "}
            </p>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-4">
            <h4> Choose a Format Designed for Your Busy Life</h4>
            <p style={{ lineHeight: "1.6rem", fontSize: "1rem" }}>
              {" "}
              Join this 8-week, Data analysis course online or at CBD in Abuja,
              Nigeria. Or, take part from the comfort of home — wherever you’re
              based — via our Remote classroom.
            </p>
          </div>
        </div>
      </div>
      <div className="front_img shadow-lg">
        <div className="container">
          <div className="row d-flex flex-row-reverse mx-auto text-white">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7 ">
              <h2
                className="display-2 font-weight-bolder pt-5"
                style={{ fontWeight: "bolder", color: "#fff" }}
              >
                Inside Our Best-in-Class Curriculum
              </h2>
              <h4 className="pt-5">
                <strong>Created With a Focus on Real-World Relevance</strong>
              </h4>
              <p
                className="py-2 font-weight-bold"
                style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              >
                Concentrate on the most important tools for data scientists on
                the job. GMT’s data analysis advisory board regularly curates
                the best practices and innovative teaching approaches of our
                entire expert network to emphasize real-world relevance and meet
                evolving employer demands. Its work ensures that students
                graduate ready to tackle the challenges they’ll face in the
                field.
              </p>
              <h4>
                <strong>Harness the Predictive Power of Data</strong>
              </h4>
              <p
                className="mb-5"
                style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              >
                Tailored for students with quantitative or programming
                backgrounds, this course dives into the essentials of data
                science: Python programming, exploratory data analysis, data
                modeling, and machine learning. Get the hands-on experience you
                need to synthesize extremely large data sets, build predictive
                models, and tell a compelling story to stakeholders.
              </p>
              <h4>
                <strong>Get a Head Start With Self-Paced Prep Lessons</strong>
              </h4>
              <p
                className="mb-5"
                style={{ lineHeight: "1.6rem", fontSize: "1rem" }}
              >
                Once you enrol, we equip you with online, self-paced preparatory
                lessons to set you up for success. Dive into essential Data
                Analysis concepts to gain a common vocabulary and start
                collaborating with classmates on day one.
              </p>
            </div>
            <div className="py-4 text-center">
              <a href={Pdf} className="btn btn-dark btn-sm-center ">
                Download Curriculum
                <br></br>
                <span>
                  <FaDownload size={20} />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 text-center my-auto justify-self-end">
            <img
              src="./images/abayo.jpg"
              alt=""
              width="100"
              className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
            />
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <strong>Data Analyst</strong> <br /> "Dada"
            </p>
            <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              <em>
                "The material we learned is relevant and meaningful. I learned a
                lot and will put much of what I learned into practice where I
                work. This course has given me the tools to be a more technical
                analytics leader in my organization.”
              </em>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark text-white">
        <div className="container p-5">
          <h2 className="text-center">Flexible Financing Options</h2>
          <hr />
          <div className="row">
            <div className="col-sm-12  col-md-6 col-lg-6 col-xl-6">
              <h4>Full Tuition</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Save #5,000 on the full #180,000 tuition by paying in full
                upfront.
              </p>

              <h4>#180,000</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Full tuition
              </p>
            </div>
            <div className="col-sm-12  col-md-6 col-lg-6 col-xl-6">
              <h4>Installments</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                Divide tuition into two, three, or four easy payments.
              </p>
              <h4>60% upfront</h4>
              <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
                per installment
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center pt-2">
        <h5>Need advice? Our Admissions team is here to help.</h5>
        <button className="btn btn-dark mb-2">
          <a
            style={{ textDecoration: "none", color: "#fff" }}
            href="mailto:greatmindsoftware@gmail.com"
          >
            Contact Admissions
          </a>
        </button>
      </div>
      <div className="container">
        <h2>Frequently Asked Questions</h2>
        <details className="mt-5">
          <summary>Why is a data Analysis skill set relevant today?</summary>
          <p
            className="py-4"
            style={{ lineHeight: "1.6rem", fontSize: "14px" }}
          >
            Companies of all stripes use data science to take on today’s biggest
            challenges, tackling everything from public policy and robotics to
            dating and eCommerce. As a result, organizations are moving quickly
            to build robust in-house teams of data Analyst and advanced
            analysts, and there’s not enough talent to go around. According to
            Burning Glass, “Data analytics skills are now widely in demand in
            decision-making roles, including managers across a range of
            industries. In fact, our data shows that more than 1.7 million job
            postings asked for data analyst skills in 2018.” Learning this
            future-proof skill set can help you enter the next stage of your
            career, whether that’s advancing in your current profession or
            exploring an exciting and lucrative field.
          </p>
        </details>
        <hr />
        <details>
          <summary>
            What skills are assessed by the end of the programme?
          </summary>
          <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
            In order to earn a certificate, you will be evaluated based on your
            ability to: Perform exploratory data analysis with Python. Build and
            refine machine learning models to predict patterns from data sets.
            Communicate data-driven insights to technical and non-technical
            audiences alike. You will also need to: Attend all class sessions.
            Complete all homework assignments that lead up to the final project.
            Complete your final project and present it.
          </p>
        </details>
        <hr />
        <details>
          <summary>What does my tuition cover?</summary>
          Here are just some of the benefits you can expect as a GMTsoftware
          student:
          <ul>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              {" "}
              Build technical competencies amongst your non-technical audience
              and equip cross-functional teams to work confidently with
              technology stakeholders..
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              The technical vocabulary needed to communicate confidently with
              technical teams.
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              Software engineers who want to apply their programming skills
              toward a new career.
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              Ultimately, this programme attracts a community of eager learners
              who have an interest in manipulating large data sets and
              forecasting to impact strategy and bottom lines.
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              Robust coursework, including expert-vetted lesson decks, project
              toolkits, and more. Refresh and refine your knowledge throughout
              your professional journey as needed.
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              A real-world project where you’ll build an interactive, android
              app from scratch.
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              Individual feedback and guidance from instructors and TAs. Stay
              motivated and make the most of your experience with the help of
              GMTsoftware’s dedicated team.
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              Exclusive access to alumni discounts, networking events, and
              career workshops.
            </li>

            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              A GMTsoftware course certificate to showcase your new skill set on
              LinkedIn.
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              Connections with a professional network of instructors and peers
              that lasts well beyond the course. The global GMTsoftware
              community can help you navigate and succeed in the field.
            </li>
          </ul>
        </details>
        <hr />
        <details>
          <summary>Are there any prerequisites</summary>
          <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
            This is a fast-paced course with some prerequisites. Students should
            be comfortable with programming fundamentals, core Python syntax,
            and basic statistics. Upon enroling, you’ll complete a short
            onboarding task and, based on your results, may be advised to take
            an introductory Python workshop. You’ll also complete up to 12 hours
            of online preparatory lessons that will ensure you have the
            foundations to dive into rigorous coursework.
          </p>
          <ul>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              Your own laptop (PC or Mac). It must be no more than five-years
              old and able to run the most recent operating system.
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              If remote, a webcam, headphones, and good access to the internet
              are required.
            </li>
          </ul>
        </details>
        <hr />

        <hr />
        <details>
          <summary>Can I work full-time while enroled in this course?</summary>
          <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
            Yes! All of our evening and 1-week courses are designed for busy
            professionals with full-time work commitments. Depending on your
            location, classes meet twice per week in the evenings or all day on
            Saturday. If you’re able to dedicate a full week to learning, we
            also have a full-time, accelerated 1-week option. Please keep in
            mind that our remote course is live online, which means that if you
            take the accelerated programme, you’ll be connected live with your
            instructor and classmates the whole day.
          </p>
        </details>
        <hr />
        <details>
          <summary>
            Which format should I take this course in — on campus or online?
          </summary>
          <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
            Which format should I take this course in — on campus or online?
            It’s up to you! Our Remote courses offer a learning experience that
            mirrors GMTsoftware’s on-campus offerings but allow you to learn
            from the comfort of home. If you don’t live near Maitama or live in
            Abuja, have a busy travel schedule, or just want to save yourself
            the commute, a Remote course could be a good option for you, if
            available in your market. You’ll still get access to the expert
            instruction, learning resources, and support network that
            GMTsoftware is known for. If you prefer to learn alongside your
            peers and can make it to Maitama campus, our in-person courses allow
            you to take advantage of our beautiful classrooms and workspaces.
            Our Admissions team can advise you on the best format for your
            personal circumstances and learning style.
          </p>
        </details>
        <hr />

        <details>
          <summary>What is the remote experience at GMTsoftware?</summary>
          <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
            Engage in live, online, project-based learning that’s designed to
            transform your career — from anywhere. Powered by Zoom, the major
            video conferencing tool, and the collaborative messaging platform
            Slack, our interactive classroom mirrors what you’ll encounter as
            part of the modern workforce. You’ll be able to not only share your
            screen and present your work to classmates but also collaborate in
            small groups via breakout rooms. Additionally, you can revisit
            recordings of className sessions in the future. Beyond className
            hours, get guidance, feedback, technical assistance, and more during
            frequent one-on-one check-ins and office hours.
          </p>
        </details>
        <hr />
        <details>
          <summary>
            What does student success look like for this course?
          </summary>
          <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
            While our Abuja campus is new, our evening and 1-week courses have
            an 91% completion rate and consistently earn an average 52 net
            promoter score.
          </p>
        </details>

        <hr />
        <details className="mb-5">
          <summary>In what language is this programme taught?</summary>
          <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
            Our courses will be delivered in four ways:
          </p>
          <ul>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              Fully in English{" "}
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              In Yoruba, with English materials{" "}
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              In Hausa, with English materials{" "}
            </li>
            <li style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
              In Igbo, with English materials{" "}
            </li>
          </ul>
          <p style={{ lineHeight: "1.6rem", fontSize: "14px" }}>
            Please check with the Admissions team to confirm which is true of
            your course of interest and what is relevant for your situation.
          </p>
        </details>
      </div>
      <Footer />
    </>
  );
}

export default Datascience;
