import React from 'react'
import DensitySmallIcon from '@mui/icons-material/DensitySmall';

function Stages() {
  return (
    <div>
        <DensitySmallIcon style={{fontSize: "80px"}}/>
        <h4 className='pt-2 text-align-center'>Stage</h4>
        <h2>{3}</h2>
    </div>
  )
}

export default Stages