import React from "react";
import "./Home3.css";

function Home5() {
  return (
    <>
      <div className="container mt-5">
        <h2
          className="text-center"
          style={{
            fontFamily: "roboto,sans-serif",
            color: "#B82F24",
            fontSize: "33px",
            fontWeight: "500",
          }}
        >
          What Our Alumni Are Saying
        </h2>
        <hr style={{ fontWeight: "10px", color: "red" }} />
        <p className="text-center" style={{ fontSize: "16px" }}>
          <i>
            Over the years, we are happy to have raised remarkable developers
            who are working in various companies across the country and beyond.
            We can however feature an alumni for each year due to space
            constraints:
          </i>
        </p>
        <div class="row row-cols-1 row-cols-md-3 g-4">
          <div class="col">
            <div class="card text-center">
              <img
                src="./images/usman.jpg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Lawal Usman
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Python Developer at FSDH MERCHANT BANK, United Kingdom
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(August 2021 set)</i>
                </p>
                <p class="card-text">
                  I was impressed with the learning culture that grounded me in
                  the fundamentals of programming as well as industry standards.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/charles.jpeg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Charles
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  CEO, NEXRON (Freelance Software Developer) Jabi, Abuja{" "}
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(June 2024)</i>
                </p>
                <p class="card-text">
                  After completing a six-month training at GMTsoftware in Abuja,
                  I also did an internship with GMTsoftware, I started my own
                  Digital Agency Called Nexron afterward
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/lucy1.jpg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Lucy Obahor
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  SIMPU (Backend Developer) Maryland, Lagos{" "}
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(April 2023)</i>
                </p>
                <p class="card-text">
                  After completing a six-month training at GMTsoftware in Abuja,
                  I secured my first internship. I was offered the opportunity
                  to become a permanent staff member after my six months
                  internship.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/abraham.jpeg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Abraham
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Internship (Opay, Abuja){" "}
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(May 2024)</i>
                </p>
                <p class="card-text">
                  After completing a Four-month training at GMTsoftware in
                  Abuja, I secured my first internship. I was offered the
                  opportunity to become a permanent staff member after my
                  internship.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/mariampass.jpg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Mariam Tajudeen
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  INFOSTRATEGY TECHNOLOGY LIMITED, ABUJA (DIGITAL MARKETING
                  SPECIALIST/UI/UX)
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(March 2022 set)</i>
                </p>
                <p class="card-text">
                  As the 8-week bootcamp drew to a close, I successfully
                  designed a product for an e-commerce store that was
                  implemented on both web and mobile applications.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/bukunmipass.jpg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Adeyemo Bukunmi
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Full-Stack Developer Punch Group,United States (remote)
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i></i>
                </p>
                <p class="card-text">
                  After resigning from a low-paying job in Lagos, I applied for
                  Full Stack Development training at the GMTSOFTWARE Bootcamp in
                  Abuja. Just a week after completing the program, I landed an
                  international job. During my time at GMTSOFTWARE, I developed
                  the habit of coding and debugging.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/olaitan.jpg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Olaitan Micheal
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  YOUNGSTAR FOUNDATION, ABUJA. (FULL STACK DEVELOPER) Now
                  Working with Punch Group (USA)
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(April 2022 set)</i>
                </p>
                <p class="card-text">
                  GMTSOFTWARE is one of the best places to learn. My instructor,
                  Mr. Bayo, is well-grounded and always available for
                  mentorship.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/samantha.jpg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Samantha Ekpenyong
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Freelance UI/UX designer/Product Design
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(january 2021 set)</i>
                </p>
                <p class="card-text">
                  GMTSOFTWARE still ranks very high among the decisions I have
                  made. The impact of their teaching and mentorship on my career
                  has been massive.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/tolu.jpeg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Agun Toluwalagbara
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  MIRABILIA NIGERIA, ABUJA. APP DEVELOPER (Internship)
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(October 2022 set)</i>
                </p>
                <p class="card-text">
                  When it comes to learning coding and changing careers to the
                  tech industry, GMTSOFTWARE is the best choice.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/brendan.jpeg"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Brendan Obilo
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  Software Developer
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(July 2022 set)</i>
                </p>
                <p class="card-text">
                  After completing my training at GMTSOFTWARE, I landed my first
                  collaborative job just a few months later. It truly is the
                  best place to learn and gain hands-on experience with
                  practical projects
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/dayo.png"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Agun Adedayo
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  MIRABILIA NIGERIA, ABUJA. UI/UX designer/Product
                  Design(Internship)
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(August 2022 set)</i>
                </p>
                <p class="card-text">
                  GMTsoftware gave me an edge and a soft landing into Tech, I
                  got my first internship immediately after my final project{" "}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img
                src="./images/isaya.png"
                alt=""
                width="100"
                className="img-fluid rounded-circle mb-3 mt-2 img-thumbnail shadow-sm text-center mx-auto"
              />
              <div class="card-body">
                <h6
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Ishaya Martins
                </h6>
                <p
                  class="card-title text-center"
                  style={{
                    fontFamily: "roboto,sans-serif",
                    color: "#B82F24",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  JBL MEDIA MINNA(Fullstack Developer)
                </p>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  <i>(October 2022 set)</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home5;
