import React from 'react'
import CastForEducationIcon from '@mui/icons-material/CastForEducation';

function Progress() {
  return (
    <div><CastForEducationIcon style={{fontSize: "80px"}}/>
     <h4 className='pt-2 text-align-center'>Track & Course</h4>
        <h4>Javascript</h4>
        <h4>{10} Course</h4>
    </div>
  )
}

export default Progress