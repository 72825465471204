import React from "react";
import Navbar from "../../Navbar";
import "./Certificate.css";
import { Helmet } from "react-helmet";

function CertificateBar(props) {
  return (
    <>
      <Helmet>
        <title>
          Coding Bootcamp in Abuja | Search Engine Optimization training in
          Abuja
        </title>
        <link
          rel="canonical"
          href="https://www.gmtsoftware.tech/devops-training-in-abuja-nigeria-complete-devops-engineer-course-in-abuja/"
        ></link>

        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_us" />
        <meta property="og:url" content="https://gmtsoftware.tech/" />
        <meta
          property="og:title"
          content="Product Designer | UX/UI designer training in Abuja"
        />
        <meta
          property="og:description"
          content="GMTsoftware is a Computer training school & Software Training Institute and the top coding Bootcamp with campus in Abuja,Lagos"
        />
        <meta property="og:image" content="./images/logo.png" />
        <meta property="og:image:type" content="./images/logo.png" />
        <meta property="og:image:width" content="359" />
        <meta property="og:image:height" content="359" />
        <meta property="og:site_name" content="GMTsoftware" />
        <meta
          property="og:see_also"
          content="https://www.twitter.com/GMTsoftware"
        />
        <meta
          property="og:see_also"
          content="https://www.facebook.com/GmtsoftwareNG"
        />
      </Helmet>
      <Navbar />
      <form className=" p-2 rounded d-flex justify-content-center align-item-center ">
        <div className="form-row">
          <h1 className="text-center text-white display-1">
            Welcome to our Certificate Authentication Portal
          </h1>

          <div className="col-lg-12 col-md-12 col-sm-10 col-xs-6 mb-3 mx-auto">
            <input
              type="text"
              className="form-control"
              id="validationServer01"
              name="fname"
              placeholder="Input your Certification Nos"
              required
              onChange={(e) => props.onSearch(e.target.value)}
              value={props.value}
            />
            <div className="valid-feedback">Looks good!</div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CertificateBar;
