import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'course_name', headerName: 'Course Name', width: 300 },
  { field: 'description', headerName: 'Description', width: 300 },
  { field: 'duration', headerName: 'Course Duration', width: 300 },
  
];

const rows = [
  {id:1, course_name: "HTML", description: 'Hypertext Markup Language', duration: '1 week'},
  {id:2, course_name: "CSS", description: 'Cascading Style Sheet', duration: '2 week'},
  {id:3, course_name: "JS", description: 'Javascript Programming language', duration: '1 month'},
  {id:4, course_name: "Reactjs", description: 'Javascript Framework', duration: '2 week'},
  {id:5, course_name: "Nodejs", description: 'Server Side Programming Language', duration: '1 month'},
  // { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  // { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  // { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },

];

export default function Courses() {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={2}
        rowsPerPageOptions={[5]}
        
      />
    </div>
  );
}
