import React from 'react'
import './Home3.css'

function Home6() {
  return (
    <>
    <div className="container mt-5">
    <h2 className='text-center' style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"33px", fontWeight:"500"}}>Featured Participants' Bootcamp Projects</h2>
    <hr style={{fontWeight:'10px' ,color:"red"}}/>
    <p className='text-center' style={{fontSize:"16px",}}><i>
    We adopted project-based learning. Here are some applications developed by our participants during the programme:</i></p>
    <div class="row row-cols-1 row-cols-md-2 g-4">
    <div class="col">
    <div class="card">
    <img
                  src="./images/foodapp.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Food Ordering App</h5>
        <p class="card-text">An online ordering application where a food vendor/ restaurant can display their menu; allowing their prospective customers to place order at their convenience.</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href=" https://drive.google.com/file/d/1osQSfNj_fiag4DtgGHztKw9xZ3j93OnE/view?usp=sharing" class="card-link">Project Link</a>
   
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/fintech.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>UI/UX Design of Sunrise Banking App by James</h5>
        <p class="card-text">
        <p class="card-text">User-Friendly Design. Perhaps the most important feature customers want in a fintech site is a good user experience.</p>
        <p class="card-text">Mobile-Friendly Features. ...</p>
        <p class="card-text">Security. ...</p>
        <p class="card-text">Payment System</p>
        <p class="card-text">Streamlined Onboarding ...</p>
        <p class="card-text">Simplify complex data into action</p>
        <p class="card-text">User Features and Friction-Free Forms ...</p>
        </p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href=" https://drive.google.com/file/d/1nVFDRIibEPkm2NXE9khU1UweRvTn3C5z/view?usp=sharing" class="card-link"> Project Link</a>
   
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/mariam.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
   <div class="card-body">
        <h6 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Product Design/ Ui/Ux  For a Sweet and Sexy Lingeries Store by Mariam</h6>
        <p className='text-center' style={{fontSize:"12px",}}><i>(April 2022 set)</i></p>
        <p class="card-text">
        <p class="card-text">User-Friendly Design. Perhaps the most important feature customers want in an e-commerce site is a good user experience.</p>
        <p class="card-text">Mobile-Friendly Features. ...</p>
        <p class="card-text">Multiple Payment Options. ...</p>
        <p class="card-text">24/7 Customer Service. ...</p>
        <p class="card-text">User Reviews. ...</p>
        <p class="card-text">User Features and Discounts. ...</p>
        Extensive Product Information.
        </p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉</label>
    <a href="https://1drv.ms/b/s!AkDncPPZYgzrbOYKJNsQ-ZxA8_E" class="card-link">Project Link</a>
  </div>
      
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/brightbooks.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}} >Brightbooks Online Book store by Bukunmi</h5>
        <p class="card-text">This store was develop by Adeyemo Bukunmi it is a mern stack project(Mongodb, React, Node, Express) its give access to both customers to choose book of there choice and get and also the admin to upload</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    {/* <a href="https://brightbooks.com.ng/" class="card-link">Project Link</a> */}
    <a href="https://github.com/BukunmiPeter/Brightbooks-Ecommerce-frontend" class="card-link">Github Link</a>
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/backend.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>GMTsoftware Blog Backend by Usman</h5>
        <p class="card-text">This was developed by Usman. He made use of django restful framework to develop a backend for gmtsoftware blog Projects</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href="https://gmtblog-api.herokuapp.com/" class="card-link">Project Link</a>
    <a href="https://github.com/owolabiadebayo/gmtblog-api" class="card-link">Github Link</a>
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/portal.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Student Portal system for GMTsoftware</h5>
        <p class="card-text">This project was executed by Dada Wole,Adeyemo Bukunmi,Owolabi Adebayo. the technology used is react-js with material-UI and python (Django) for backend its used to manage student tasks and monitor their progress</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href="https://gmtsoftware.tech/Dashboard" class="card-link">Project Link</a>
  </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
    <img
                  src="./images/patientappointment.gif"
                  alt=""
                  width="100"
                  className="img-fluid card-img-top"
                />
      <div class="card-body">
        <h5 class="card-title text-center" style={{fontFamily:"roboto,sans-serif",color:"#B82F24", fontSize:"20px", fontWeight:"500"}}>Hospital Management System</h5>
        <p class="card-text">Patient appointment system helps you manage appointments and seamlessly check-in patients to see consultants on arrival. Therefore, staff can focus on meeting and supporting patients to deliver a better all-round experience.</p>
      </div>
      <div class="card-body">
      <label>Click Here to view 👉 </label>
    <a href=" https://github.com/owolabiadebayo/Online-Hospital-Management" class="card-link">Github Link</a>
   
  </div>
    </div>
  </div>
</div>
<a href="/Project" className="btn btn-dark btn-md-center">
                  
                  <span>
                  See More Projects 👉
                  </span>
                </a>
</div>
    </>
  )
}

export default Home6