import React, { useEffect } from "react";
import { FcHome } from "react-icons/fc";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function Success() {
  useEffect(() => {
    document.title = "GMTsoftware | Success";
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Tech School in Abuja | 10 most lucrative money making digitalskills in
          nigeria and how to learn them
        </title>
        <link
          rel="canonical"
          href="https://www.gmtsoftware.tech/tech-school-in-abuja/"
        ></link>

        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_us" />
        <meta
          property="og:url"
          content="https://www.gmtsoftware.tech/tech-school-in-abuja/"
        />
        <meta
          property="og:title"
          content="Tech School in Abuja | 10 most lucrative money making digitalskills in
          nigeria and how to learn them"
        />
        <meta
          property="og:description"
          content="GMTsoftware is a Tech school & Software Training Institute and the top coding Bootcamp with campus in Abuja,Lagos"
        />
        <meta property="og:image" content="./images/logo.png" />
        <meta property="og:image:type" content="./images/logo.png" />
        <meta property="og:image:width" content="359" />
        <meta property="og:image:height" content="359" />
        <meta property="og:site_name" content="GMTsoftware" />
        <meta
          property="og:see_also"
          content="https://www.twitter.com/GMTsoftware"
        />
        <meta
          property="og:see_also"
          content="https://www.facebook.com/GmtsoftwareNG"
        />
        <meta
          name="keywords"
          content="Digital Marketing: SEO, content, social media, Web Development: HTML, CSS, JavaScript, frameworks, Graphic Design: Photoshop, Illustrator, design principles,Content Creation: Writing, video, audio,E-commerce: Online store setup, marketing,Data Analytics: Data analysis tools, statistics, machine learning,Cyber Security: Ethical hacking, network security,UI/UX Design: User experience, design software,App Development: Swift, Kotlin, React Native,Virtual Assistance: Administrative tasks, customer service"
        />
        <meta
          name="description"
          content="GMTsoftware is a Computer training school & Software Training Institute, Kids Coding School and the top coding Bootcamp with campus in Abuja,Lagos, Nigeria."
        />
      </Helmet>
      <div className="container-fluid">
        <div className="container">
          <h1 className="text-center display-3 py-5">
            Dear applicant, thanks for applying!
          </h1>
          <p className="py-3">
            Your application has been received, We’re thrilled to hear from you.
            Our admission team can’t wait to process your application, you'll
            receive a short message soon in your email address for next step
            regarding your application. Cheers{" "}
            <span role="img" aria-labelledby="emoji">
              🎉🎉🎉!
            </span>
          </p>

          <div className="text-center">
            <Link to="/" className="btn btn-normal btn-dark m-5">
              Back to Home <FcHome size={35} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Success;
