import { styled, createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { mainListItems } from "./ListItems";
import Chart from "./Chart";
import Stages from "./stages";
import Task from "./Task";
import Task2 from "./Task2";
import PrimarySearchAppBar from "./Appbar";
import Performance from "./Performance";
import axios from "axios";
import { useEffect,useState } from "react";
import { makeStyles } from "@mui/styles";
import Point from "./points";
import Progress from "./Progress";
import Courses from "./Courses";
import Progressbar from "./Progressbar";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://gmtsoftware.tech/">
        www.gmtsoftware.tech @
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles({
  paper:{
    color: "#bf360c"
  }
})


const drawerWidth = 200;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  window.onload = function() {
    if(!window.location.hash) {
      window.location = window.location + '#loaded';
      window.location.reload();
    }}
  
  useEffect(()=>{
   loadUserData() 
  })


  const loadUserData = async (e) => {
    let res = await axios.get("https://studentportal.gmtsoftware.tech/portaluser/EachTask/")
     if(res.data){
      const user = localStorage.getItem('user');
      const UserData = JSON.parse(user)
      const Dashboarduser = res.data.filter((data) => data.EachUser === UserData.User )
      localStorage.setItem("Dashboarduser",JSON.stringify(Dashboarduser))
    }
    return res.data
     }
  
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <PrimarySearchAppBar></PrimarySearchAppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">{mainListItems}</List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} >
             
              <Grid item xs={12} md={3} lg={3} >
                <Paper
                elevation={3}
                style={{color: 'white', backgroundColor: '#e91e63'}}
                
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 200,
                    alignItems: "center",
                    justifyContent: "center"
                  
                  }}
                >
                <Stages/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Paper
                style={{color: 'white', backgroundColor: '#009688'}}
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 200,
                  alignItems: "center",
                  justifyContent: "center"
                
                }}
                >
                  <Point/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Paper  style={{color: 'white', backgroundColor: '#01579b'}}
                 sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 200,
                  alignItems: "center",
                  justifyContent: "center"
                
                }}
                >
                  <Progress/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Paper  style={{color: 'white', backgroundColor: 'white'}}
                 sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 200,
                  alignItems: "center",
                  justifyContent: "center"
                
                }}
                >
                  <Progressbar/>
                </Paper>
              </Grid>
              {/* <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 400,
                  }}
                >
                  <Performance />
                </Paper>
              </Grid> */}
              {/* Chart */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 240,
                  }}
                >
                  <Chart />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 400,
                  }}
                >
                  <Task />
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 300,
                  }}
                >
                  <Task2 />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 300,
                  }}
                >
                  <Courses />
                </Paper>
              </Grid>
              
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function Dashboard() {


  return <DashboardContent />;
}

export default Dashboard;
